import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/admin'
const modules = '/upload'
const path = base + modules

const getUploadUrl = path // 獲取上傳鏈接
const getCustomerUploadUrl = path + '?filename=' // TODO 路由會再改 - (訪客)獲取上傳鏈接

// request(REQ) Function  --------------------------------

const getUploadUrlFn = (name, callback) => { // 獲取角色列表
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getUploadUrl + '?filename=' + name, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 前端上傳檔案
 * @param {String} url
 * @param {object} base64String 檔案
 * @param {Object} onRequestCallback 回調函數
 * @param {Object} callback 回調函數
 */
const uploadImg = (url, base64String, onRequestCallback, callback) => {
  return useCustomFetch(url, {
    method: 'PUT',
    body: base64String,
    onRequest: onRequestCallback,
    onResponse: callback
  })
}
const getCustomerUploadUrlFn = (name, callback) => { // 獲取角色列表
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getCustomerUploadUrl + name, {
    method: 'GET',
    onResponse: callback
  })
}

export {
  getUploadUrlFn,
  uploadImg,
  getCustomerUploadUrlFn
}
